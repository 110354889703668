@import "/src/commonStyles/mixins.scss";

.QM-input {
  &_wrapper {
    @include rubik;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    margin-bottom: 24px;
    position: relative;

    .QM-textarea {
      &_note {
        @include rubik;
        font-size: 12px;
        line-height: 18px;
        color: var(--col-label-text);
      }
    }
  }
  &_label {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    color: var(--col-label-text);
  }
  &_requiredMark {
    color: var(--col-status-error);
    margin-left: 3px;
  }
  &_errorMessage {
    font-size: 12px;
    line-height: 15px;
    font-family: Inter;
    font-weight: 400;
    color: var(--col-status-error);
    position: absolute;
    left: 0;
    top: 100%;
    padding-bottom: 12px;
  }

  @include rubik;
  font-size: 14px;
  line-height: 20px;
  color: var(--col-active-text);
  background-color: var(--col-bg-2);
  border-radius: var(--shape-radius);
  border: 1px solid var(--col-input-border);
  padding: 10px 12px;
  outline: none;
  cursor: pointer;

  &::placeholder {
    color: var(--col-inactive-text);
  }
  &:focus {
    border: 1px solid var(--col-btn);
  }
  &:disabled {
    background-color: var(--col-input-bg-disabled);
		color: var(--col-label-text);
  }

  &__withIcon {
    padding-left: 44px;
  }

  &__error {
    border: 1px solid var(--col-status-error);
  }

  &.QM-datepicker {
    width: 100%;
  }
}
