@import "/src/commonStyles/mixins.scss";

.QM-checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  &_label {
    position: relative;
    cursor: pointer;
    span {
      margin-left: 6px;
    }
    &::before {
      content: "";
      -webkit-appearance: none;
      background-color: var(--col-dropdown-border);
      border-radius: 2px;
      padding: 9px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  &:checked + &_label::before {
    background-color: var(--col-btn);
  }
  &:checked + &_label::after {
    @include icomoon;
    content: "\e92f";
    font-size: 14px;
    color: var(--col-white);
    display: block;
    position: absolute;
    top: 4px;
    left: 2px;
  }
}
