@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-landing {

  @include poppins(200);

  h1,
	h2,
	h3,
	h4 {
		font-weight: 600;
		line-height: 1.3;
		padding-top: .5em;
		padding-bottom: .5em;
	}
	h1 {
		font-size: calc(1rem + 4.301075vw);
		@media (min-width: 1024px) {
			font-size: 60px;
		}
	}
	h2 {
		font-size: calc(1rem + 4.301075vw);
		@media (min-width: 1024px) {
			font-size: 60px;
		}
	}
	h3 {
		font-size: calc(1rem + 2.34599vw);
		@media (min-width: 1024px) {
			font-size: 40px;
		}
	}
	h4 {
		// font-size: calc(1rem + .775vw);
		// @media (min-width: 1024px) {
		// 	font-size: 24px;
		// }
		font-size: calc(1rem + 1.56vw);
		font-weight: 700;
		@media (min-width: 1024px) {
			font-size: 32px;
		}
	}
	p {
		padding-top: 0.25em;
		padding-bottom: 0.25em;
    line-height: 1.5;
	}

  main {
    padding: 0;
  }

	footer {
		background: #000;
		color: #fff;
		font-size: 14px;
		div {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;
			text-align: center;
			span {
				white-space: nowrap;
			}
			p + p {
				margin-top: 2em;
			}
			@media (min-width: 480px) {
				flex-direction: row;
				text-align: left;
				p + p {
					margin-top: 0;
				}
			}
			a.icon-linkedin {
				background: #0073b2;
				border-radius: 4px;
				color: #fff;
				transition: all .15s ease-in 0s;
				&::before {
					display: block;
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
				span {
					display: none;
				}
				&:hover,
				&:focus {
					background: lighten(#0073b2,5%);
				}
				&:active {
					background: lighten(#0073b2,10%);
				}
			}
		}
	}

  .custom__commonWidth {
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;
		padding: 50px 6.25%;
	}
}