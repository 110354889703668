@import '/src/commonStyles/vars.scss';

// styles for input may be found in 
// '\src\components\estimator\common\QMInput\styles.scss'

.QM_E-datepicker_wrapper {
  position: relative;

  & > svg {
    position: absolute;
    top: 34px;
    left: 16px;
    z-index: 1;
    cursor: pointer;
  }

  .QM_E-datepicker {
    padding-left: 44px;
    cursor: pointer;
    box-sizing: border-box;
    max-width: 155px;
  }

}