.QM-docsModal {
	min-width: 800px;
	padding: 0;
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid var(--col-clr-border);
		padding: 15px 24px;
		&_part {
			display: flex;
			align-items: center;
		}
		.QM-docWidget_warning {
			color: var(--col-status-warning);
			margin-right: 8px;
		}
	}
	&_body {
		padding: 15px 24px;
		padding-top: 0;
		max-height: calc(100vh - 100px);
		overflow: auto;
	}
}
