.QM-avatar {
	&_wrapper {
		background-color: var(--col-btn);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 8px;

		&__secondary {
			border: 1px solid var(--col-btn-s-active);
			background-color: var(--col-btn-s-hover);
		}
	}
	&_text {
		color: var(--col-white);
		&__secondary {
			color: var(--col-btn-s-active);
		}
	}
}
