.QM-deals_container {
  // display: grid;
  // grid-template-columns: 1fr 300px;

  & > section:first-child {
    padding: 14px;
  }

  .QM-table_scroller {
    height: calc(100vh - 24px);
		// width: calc(100vw - 564px); // 264px - nav menu, 300px - sidebar
		width: calc(100vw - 292px); // 264px - nav menu, 28px - margins (2x14px)
    overflow: auto;

    thead tr th {
      position: sticky;
      top: 0;
      box-shadow: inset 0px -1px 0px #ebeff2;
      background-color: var(--col-bg-2);
      z-index: 1;
    }
  }

  .QM-table {
    &__deal-name_new-line {
      max-width: 170px;
      white-space: pre-wrap;
      text-align: left;
    }
  }
}
