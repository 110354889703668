.tabs-submit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--col-white);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

  &__btn {
    margin: 4px 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
