#landing__Neither {
  background: #f2fcff;
  text-align: center;
  color: #060935;
  h3 {
    max-width: 14em;
    margin-left: auto;
    margin-right: auto;
    + p {
      margin-left: auto;
      margin-right: auto;
      font-size: 1.25em;
      max-width: 30em;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 1em;
    li {
      width: 100%;
    }
    @media (min-width: 768px) {
      flex-direction: row;
      li {
        width: calc(100% / 3 - 1em);
        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
    @media (min-width: 900px) {
      li {
        &:nth-child(2) {
          flex-grow: 0;
        }
      }
    }
    @media (min-width: 1024px) {
      li {
        width: calc(100% / 3 - 2em);
      }
    }
    @media (min-width: 1200px) {
      li {
        width: calc(100% / 3 - 3em);
      }
    }
    h4 {
      &[class^="icon-"],
      &[class*=" icon-"] {
        display: block;
        &::before {
          font-size: 72px;
          display: block;
          margin-bottom: 0.125em;
        }
      }
      img {
        display: block;
        margin: 2em auto 1rem;
      }
    }

  }
}