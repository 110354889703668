.deal-info {
  &__title,
  &__close {
    .QM-btn__close-text {
      margin-right: 12px;
      margin-top: 6px;
      color: var(--col-btn);
      transition: transform 0s;
      padding: 10px 15px;
      border: 1px solid transparent;

      &:hover {
        background-color: var(--col-btn-s-hover);
        border: 1px solid var(--col-btn);
      }

      &:active {
        transform: scale(0.95);
        background-color: var(--col-btn-s-active);
      }
    }
  }

  &__body {
    display: flex;
    border-bottom: 1px solid var(--col-clr-border);
  }

  &__q-score {
    padding: 45px;
    border-right: 1px solid var(--col-clr-border);

    &-name {
      font-size: 14px;
      line-height: 12px;
      color: var(--col-label-text);
    }

    &-score,
    &-no-score {
      line-height: 36px;
    }

    &-score {
      font-size: 26px;
      color: var(--col-status-success);
    }

    &-no-score {
      font-size: 14px;
      color: var(--col-label-text);
      margin-left: -4px;
    }
  }

  &__title {
    display: flex;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--col-clr-border);
  }

  &__project-name {
    margin: 14px 40px 14px 24px;
		word-break: break-word;
  }
  &__content {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, 200px);
    padding-left: 14px;
    padding-right: 14px;
    &:first-child {
      padding-top: 16px;
    }
    &:last-child {
      padding-bottom: 16px;
    }

    @media screen and (max-width: 1270px) {
      grid-template-columns: 200px 200px 120px 200px 200px;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 190px 190px 120px 190px 150px;
    }

    @media screen and (max-width: 1160px) {
      grid-template-columns: 170px 180px 120px 170px 150px;
    }

    @media screen and (max-width: 1080px) {
      grid-template-columns: 170px 160px 110px 150px 150px;
    }
  }

  .QM-modal {
    &_bg {
      position: fixed;
      z-index: 100000;
    }

    &_content {
      border-radius: 4px;
      padding: 24px;
    }
  }

  .deal-page-modal {
    max-width: 400px;
    &__body {
      max-width: 352px;
      margin-top: 14px;
    }

    &__footer {
      .tabs-submit__btn {
        position: static;
        left: 0;
        transform: translateX(0);
      }

      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }

    &__title,
    &__text,
    &__subtext {
      font-weight: 400;
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__text,
    &__subtext {
      font-size: 14px;
      line-height: 20px;
    }

    &__subtext {
      margin-top: 14px;
    }
  }

  .QM-kvBlock {
    white-space: nowrap;

    @media screen and (max-width: 1200px) {
      strong {
        font-size: 12px;
        margin-right: 8px;
      }

      font-size: 12px;
    }

    @media screen and (max-width: 1030px) {
      padding-left: 0;
    }
  }
}
