@import '/src/commonStyles/vars.scss';

.QM_E-form {
  max-width: 550px;
  
  &-fields_wrapper {
    display: flex;
    flex-wrap: wrap;
    &-top {
      margin-bottom: 30px;
    }
    &-bottom {
      margin-bottom: 37px;
    }
  }

  .QM_E-btn_estimate {
    font-size: 13px;
    line-height: 18px;
    font-family: OpenSans;
    font-weight: 700;
    text-align: center;
    color: $white;
    cursor: pointer;
  
    border-radius: 4px;
    border: none;
    background-color: $primary-btn-color;
    // box-shadow: $primary-btn-shadow;
    outline: none;
  
    width: 218px;
    height: 44px;

    &:hover {
      box-shadow: $primary-btn-shadow-hover;
    }
  }
}