.QM-forbiddenCover {
	position: absolute;
	z-index: 10;
	width: 100%;
	height: 100vh;
	padding-top: 132px;
	top: 65px;
	display: flex;
	justify-content: center;
	background-color: rgba(var(--col-white), 0.5);
	backdrop-filter: blur(5px);
	&_content {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			color: var(--col-dark-inactive-text)
		}
		span {
			color: var(--col-inactive-text);
			margin-bottom: 16px;
			&::before {
				font-size: 64px;
			}
		}
	}
}
