.deal-info__assign-user {
	margin: 14px 0 14px 40px;
	display: flex;
  align-items: center;
	&__avatarWrapper {
		&:not(&:first-child) {
			margin-left: -15px;
		}
	}

	&__avatarTooltip {
		padding: 8px;
	}
	.QM-kvBlock {
		padding: 0;
	}
	.QM-avatar_wrapper {
		width: 32px;
		height: 32px;
		font-size: 12px;
	}
	&_btn {
		border: 1px dashed var(--col-label-text);
		height: 32px;
		width: 32px;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		font-family: "icomoon";
		src: url("../../../../../assets/fonts/icomoon.woff") format("woff"),
			url("../../../../../assets/fonts/icomoon.ttf") format("truetype");
		font-weight: normal;
		font-style: normal;
		font-display: block;
		background-color: var(--col-white);
		margin-left: -15px;
		box-shadow: 0 0 2px 2px var(--col-white);

		&::before,
		&::after {
			position: absolute;
		}

		&::before {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: "\e932";
			font-size: 20px;
			color: var(--col-label-text);
		}

		&::after {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			bottom: -4px;
			right: -4px;
			content: "\e903";
			border: 1px solid var(--col-label-text);
			background-color: var(--col-label-text);
			font-size: 12px;
			color: var(--col-white);
		}
	}
}
