button,
label {
	cursor: pointer;
}

a {
	text-decoration: none;
	color: $clr-text;
	@include transition;
	&:hover,
	&:focus {
		color: $clr-text-hover;
	}
	&:active {
		color: $clr-blue-light-active;
	}
	&[download] {
		span {
			text-decoration: underline;
			text-decoration-skip-ink: none;
		}
	}
}

main {
	padding-left: $aside;
}

::-webkit-scrollbar {
  width: 8px;
	height: 8px;
}
::-webkit-scrollbar-track {
  background: var(--col-bg-1);
}
::-webkit-scrollbar-thumb {
  background: var(--col-dropdown-border);
	border-radius: var(--shape-radius);
	&:hover {
		background: var(--col-inactive-text);
	}
}

.no-scroll {
	overflow: hidden;
}

.scroll-lock {
	overflow: hidden;
  margin-right: 17px;
}

.grid-2-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 14px;
	align-items: start;
}

.capitalized {
	text-transform: capitalize;
}
.QM-main_content {
	// padding-left: $article-padding;
	// padding-right: $article-padding;
	// padding-bottom: $article-padding;
	position: relative;
	min-height: 100vh;
	@include rubik;

	h2 {
		font-size: 20px;
		line-height: 28px;

	}
}

.custom__button {
	background: #1b91b6;
	font-weight: 700;
	color: #fff;
	display: flex;
	line-height: 48px;
	border-radius: 4px;
	padding-left: 1em;
	padding-right: 1em;
	transition: all .15s ease-in 0s;
	white-space: nowrap;
	font-size: 1rem;
	&:hover,
	&:focus {
		background: lighten(#1b91b6,5%);
		color: #fff;
	}
	&:active {
		background: lighten(#1b91b6,10%);
	}
}

// ---CHART VALUES---

// EXPOSURE TREND
.legend__DUNS::before,
.legend__DUNS > span {
	background: $clr-DUNS;
}
.legend__Cyber::before,
.legend__Cyber > span {
	background: $clr-Cyber;
}
.legend__QScore::before,
.legend__QScore > span {
	background: $clr-QScore;
}

// ASSETS
.legend__Office::before {
	background: $clr-Office;
}
.legend__Assets::before {
	background: $clr-Assets;
}
