@import './vars.scss';
@import './mixins.scss';

.react-datepicker {
  border-radius: 8px;
  box-shadow: $input-focus-shadow;
  border: none;

  &__header {
    border-top-left-radius: 8px;
    background-color: $input-bg;
    border-bottom: none;
    padding: 12px 0;
    padding-top: 18px;
    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 8px;
    }
  }
  &__navigation-icon {
    top: 6px;
    &::before {
      border-width: 2px 2px 0 0;
      border-color: $input-placeholder-color;
    }
    &--next {
      left: -6px;
    }
    &--previous {
      right: -6px;
    }
    &:hover {
      &::before {
        border-color: darken($input-placeholder-color, 10);
      }
    }
  }
  &__current-month {
    color: $input-text-color;
    font-family: OpenSans;
    margin-bottom: 10px;
  }
  &__day-names {
    margin-bottom: -20px;
  }
  &__day-name {
    color: $input-text-color;
    font-family: OpenSans;
  }
  &__month-container {
    background-color: $input-bg;
    border-radius: 8px;
  }
  &__month {
    margin: 10px;
  }
  &__day {
    color: $input-text-color;
    font-family: OpenSans;
    &--today {
      color: $radio-checked-bg;
    }
    &--disabled {
      color: $input-placeholder-color;
    }
    &--keyboard-selected,
    &--selected {
      color: $input-bg;
      background-color: $radio-checked-bg;
      font-weight: 600;
      &:hover {
        background-color: darken($radio-checked-bg, 10);
      }
    }
  }
}

.QM-brokerPortal {
  .react-datepicker {
    border-radius: var(--shape-radius);
    &__header {
      border-top-left-radius: var(--shape-radius);
      background-color: var(--col-bg-2);
      &:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: var(--shape-radius);
      }
    }
    &__current-month {
      color: var(--col-active-text);
      @include rubik(500);
      margin-bottom: 10px;
    }
    &__day-name {
      color: var(--col-active-text);
      @include rubik;
    }
    &__month-container {
      background-color: var(--col-bg-2);
      border-radius: var(--shape-radius);
    }
    &__day {
      color: var(--col-active-text);
      @include rubik;
      &--today {
        color: var(--col-btn);
        font-weight: 500;
      }
      &--disabled {
        color: var(--col-inactive-text);
      }
      &--keyboard-selected,
      &--selected {
        color: var(--col-white);
        background-color: var(--col-btn);
        font-weight: 500;
        &:hover {
          background-color: var(--col-btn-p-hover);
        }
      }
    }
  }
}