.QM-deals_container {
  &__admin {
    display: grid;
    grid-template-columns: 1fr minmax(300px, auto);

    & > section:first-child {
      padding: 14px;
    }

    .QM-table_scroller {
      height: calc(100vh - 24px);
			width: calc(100vw - 564px); // 264 px - navigation, 300px - sidebar
      overflow: auto;

      thead tr th {
        position: sticky;
        top: 0;
        box-shadow: inset 0px -1px 0px #ebeff2;
        background-color: var(--col-bg-2);
        z-index: 1;
      }
    }

    .QM-table {
      &__deal-name_new-line {
        max-width: 150px;
        white-space: pre-wrap;
        text-align: left;
      }

      tbody td p {
        min-width: 50px;
        max-width: 150px;
      }

      tbody td:first-child {
        padding-right: 10px;
      }
    }
  }
}
