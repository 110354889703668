@import "/src/commonStyles/mixins.scss";

.pastActivities-container {
  padding: 24px;
  p {
    @include rubik;
    @include text-regular;
  }
  strong {
    @include rubik(500);
    font-size: 12px;
    line-height: 18px;
    color: var(--col-label-text);
  }
  form {
    padding-top: 24px;
  }
  .questionBlock {
    padding-top: 24px;
    p {
      color: var(--col-label-text);
    }
    .textArea {
      margin-bottom: 0;
      &__dynamic {
        margin-top: 12px;
      }
    }

    .QM-checkbox__warning::before {
      border: 1px solid var(--col-status-warning);
      background-color: var(--col-white);
    }
  }
  .questionRow {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr repeat(3, 40px);
    align-items: center;
  }
}
