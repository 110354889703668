.QM_tooltip_name {
  font: {
    weight: 400;
    size: 14px;
  }
  line-height: 18px;
  color: var(--col-active-text);
  width: auto;
  overflow: auto;
  white-space: nowrap;

  &_text {
    cursor: pointer;
  }
}
