@import "/src/commonStyles/mixins.scss";

.risk-profile {
  .QM-tile {
    width: 100%;
    margin-top: 14px;
    padding: 24px;
  }

  &__description-header,
  &__description-body,
  &__general-header {
    color: var(--col-active-text);
    line-height: 28px;
  }

  &__description-header {
    font-size: 20px;
  }

  &__description-body {
    margin-top: 24px;
    font-size: 14px;
		word-wrap: break-word;
		word-break: break-word;
  }

  &__header {
    text-align: center;
  }

  &__plate {
    display: flex;
    section:first-child {
      margin-right: 14px;
    }
  }

  &__general-list-item {
    display: flex;
    justify-content: space-between;

    &:first-child span:last-child {
      font-weight: 500;
    }
  }

  &__general-list-item,
  &__table-list-item {
    padding: 6px;
    font-size: 14px;
    line-height: 20px;
    color: var(--col-active-text);

    &:nth-child(2n) {
      background: var(--col-table-row-hover);
    }
  }

  &__processing {
    color: var(--col-label-text);
    font-style: italic;
  }

  &__first-name {
    padding-right: 12px;
  }

  &__table-title {
    margin-top: 30px;
		padding: 6px;
    color: var(--col-label-text);
    line-height: 20px;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 120px 120px;
  }

  &__table-list-item {
    display: grid;
    grid-template-columns: 1fr 120px 120px;
  }

  &__table-error {
    color: var(--col-label-text);
  }

  &__table-success {
    color: var(--col-status-success);
  }

  &__table-warning {
    color: var(--col-status-warning);
  }

  &__stakeholders-list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @media screen and (max-width: 1110px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__stakeholders-no-data {
    display: flex;
    justify-content: center;
    margin: 48px 0;
    font-size: 20px;
    color: var(--col-label-text);
  }

  &__stakeholders-list-item {
    border: 1px solid var(--col-stakeholders-border);
    border-radius: 6px;
    padding: 24px;
    min-width: 320px;

    &__img {
      position: relative;
      top: 7px;
    }

    &__title {
      color: var(--col-btn);
      margin-bottom: 24px;

      span {
        margin-left: 8px;
      }
    }

    &__text {
      margin-top: 12px;
      color: var(--col-active-text);
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__map-info {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
  }

  svg:focus:not(:focus-visible) {
    outline: none;
  }

  &__map {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &__map-body {
    position: relative;
  }

  &__map-control {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--col-white);
    height: 30px;

    svg {
      margin: 0 10px;
      cursor: pointer;
    }

    svg:first-child {
      padding-top: 5px;
    }

    svg:last-child {
      padding-top: 5px;
    }
  }

  &__map-control-size {
    font-size: 14px;
    position: relative;
    top: -5px;
    color: var(--col-label-text);
    border-left: 1px solid var(--col-label-text);
    border-right: 1px solid var(--col-label-text);
    padding: 0 6px;
  }

  &__description {
    position: relative;
    top: -4px;
    margin-left: 6px;
  }

  &__litigations-list {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 24px;
  }

  &__litigations-body {
		display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: 24px;
  }

  &__litigations-title {
    padding-left: 24px;
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    grid-column-gap: 10px;
    border-bottom: 1px solid var(--col-clr-border);
    padding-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
    color: var(--col-label-text);
  }

  &__litigations-list-item {
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    padding: 28px 0 28px 24px;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    grid-column-gap: 10px;
		align-items: baseline;
    min-height: calc(188px + 28px);

    &:nth-child(4n-1),
    &:nth-child(4n) {
      background: #f9fafb;
    }

    &__container {
      max-width: 400px;
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__header-text {
      padding: 10px 0;
      color: var(--col-label-text);
      max-width: 260px;
    }

    &__description {
      margin-top: 12px;
			word-wrap: break-word;
			word-break: break-word;
    }

    &__red-flag {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .QM-btn-files-text {
      color: var(--col-btn);
      padding-left: 12px;
      padding-right: 16px;
      transition: transform 0s;
      letter-spacing: 0.01px;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        background-color: var(--col-btn-s-hover);
      }

      &:active {
        transform: scale(0.95);
        background-color: var(--col-btn-s-active);
      }
    }
  }

  &__asset-location {
    display: inline-block;
    margin-left: 2px;

    &::after {
      content: ", ";
    }

    &:last-of-type::after {
      content: " ";
    }
  }
}
