@import "/src/commonStyles/vars.scss";
@import "/src/commonStyles/mixins.scss";

.QM-globalSearch_resultList {
  &_item {
    @include transition;
    padding: 12px;
    font-size: 14px;
    cursor: pointer;
		display: flex;
		align-items: center;

    &:hover,
    &:focus,
    &:focus-within {
      background: $clr-background-hover;
    }

		&__disabled {
			background-color: var(--col-input-bg-disabled);
			cursor: not-allowed;

			&:hover,
			&:focus,
			&:focus-within {
				background: var(--col-input-bg-disabled);
			}

			.QM-avatar_wrapper {
				filter: saturate(0.5);
			}
		}
  }

  &_contact-name {
    color: var(--col-active-text);
    line-height: 20px;
    font-weight: 400;
		&__disabled {
			color: var(--col-inactive-text);
		}
  }

  &_contact-email {
    color: var(--col-label-text);
    font-size: 12px;
  }

	&_contact-err {
		color: var(--col-status-error);
		display: flex;
		align-items: flex-end;
		.icon-attention {
			margin-right: 4px;
			&::before {
				font-size: 16px;
			}
		}
	}
}
