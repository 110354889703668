@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  background: $clr-text;
  top: 0;
  left: 0;
  bottom: 0;
  width: $aside;
  font-size: 14px;
  a {
    @include transition;
    &:hover,
    &:link,
    &:visited {
      color: $clr-white;
    }
    &:focus {
      color: $clr-text-hover;
    }
    &:active {
      color: $clr-background-active;
    }
  }
}