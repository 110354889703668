.QM-table {
  width: 100%;
  background-color: var(--col-bg-2);
  thead {
    box-shadow: inset 0px -1px 0px #ebeff2;

    th {
      padding: 16px 0;
      text-align: start;
      color: var(--col-label-text);
      font-size: 12px;
      line-height: 18px;
      @include rubik(500);

      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }
  }
  tbody {
    tr {
      position: relative;
      .QM-table_openLinkBtn {
        visibility: hidden;
        color: var(--col-label-text);
        padding: 10px;
        span::before {
          font-size: 20px;
        }
        &:hover {
          color: var(--col-btn);
        }
        &:active {
          background-color: var(--col-btn-s-active);
        }
      }

      &::after {
        content: "";
        height: 5px;
        width: 100%;
        background: linear-gradient(to bottom, transparent, #f0f1f2);
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &:hover {
        background-color: var(--col-table-row-hover);
        .QM-table_openLinkBtn {
          visibility: visible;
        }
      }
      &:active {
        background-color: var(--col-btn-s-active);
        &::after {
          background: transparent;
        }
      }
      &.QM-table_row__hasInnerActive:active {
        background-color: var(--col-table-row-hover);
        &::after {
          background: linear-gradient(to bottom, transparent, #f0f1f2);
        }
      }
      &.QM-table_row__selected {
        background-color: var(--col-btn-s-hover);
        &::after {
          background: transparent;
        }
        &.QM-table_row__hasInnerActive:active {
          background-color: var(--col-btn-s-hover);
          &::after {
            background: transparent;
          }
        }

        .QM-table_cell__clickable {
          & > span[class^="icon-"] {
            background-color: var(--col-btn-s-hover);
          }
          &:hover {
            color: var(--col-btn);
            & > span[class^="icon-"] {
              background-color: var(--col-btn-s-hover);
              filter: drop-shadow(-5px 0 5px var(--col-btn-s-hover));
            }
          }
          &:active {
            color: var(--col-btn-p-active);
            background-color: var(--col-btn-s-active);
            & > span[class^="icon-"] {
              background-color: var(--col-btn-s-active);
              filter: drop-shadow(-5px 0 5px var(--col-btn-s-active));
            }
          }
        }
      }
    }
    td {
      min-width: 120px;
      max-width: 200px;
      vertical-align: middle;
      padding: 16px 0;

      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
        min-width: 50px;
        max-width: 70px;
      }
      p,
      .QM-table_cell__clickable {
        @include rubik;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        line-height: 20px;
        color: var(--col-active-text);
        min-width: 120px;
        max-width: 200px;
        cursor: pointer;
      }

      .QM-table_cell__clickable {
        border-radius: var(--shape-radius);
        position: relative;
        & > span[class^="icon-"] {
          position: absolute;
          background-color: var(--col-bg-2);
          right: 0;
          height: 100%;
          filter: drop-shadow(-5px 0 5px var(--col-bg-2));
          visibility: hidden;
        }
        &:hover {
          color: var(--col-btn);
          & > span[class^="icon-"] {
            visibility: visible;
            background-color: var(--col-table-row-hover);
            filter: drop-shadow(-5px 0 5px var(--col-table-row-hover));
          }
        }
        &:active {
          color: var(--col-btn-p-active);
          background-color: var(--col-btn-s-active);
          & > span[class^="icon-"] {
            visibility: visible;
            background-color: var(--col-btn-s-active);
            filter: drop-shadow(-5px 0 5px var(--col-btn-s-active));
          }
        }
      }
      strong,
      small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      strong {
        text-transform: capitalize;
      }
      small {
        font-size: 10px;
        line-height: 18px;
      }

			&.QM-table_cell__headlike {
				@include rubik;
				@include text-regular;
				color: var(--col-label-text);
			}
    }
  }
}
