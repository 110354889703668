@import '/src/commonStyles/vars.scss';
.QM_E-input {
  font-size: 14px;
  line-height: 19px;
  font-family: OpenSans;
  font-weight: 400;

  color: $input-text-color;
  background-color: $input-bg;
  border-radius: 8px;
  border: 1px solid $input-bg;
  outline: none;
  cursor: pointer;

  padding: 13px 16px;

  &::placeholder {
    color: $input-placeholder-color;
  }
  &:focus {
    border: 1px solid $input-focus-border-color;
    box-shadow: $input-focus-shadow;
  }

  &__withIcon {
    padding-left: 44px;
  }

  &__error {
    // border: 1px solid $input-error-border-color;
    padding-right: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: $input-error-text-color;
  }

  &_label {
    font-size: 12px;
    line-height: 16px;
    font-family: OpenSans;
    font-weight: 400;
    display: flex;
    margin-bottom: 4px;
    &__dark {
      color: $input-text-color;
    }
    &__light {
      color: $white;
    }
  }
  &_requiredMark {
    // color: $input-error-text-color;
    margin-right: 2px;
  }
  &_errorMessage {
    font-size: 12px;
    line-height: 15px;
    font-family: Inter;
    font-weight: 400;
    color: $input-error-text-color;
    position: absolute;
    left: 0;
    top: 100%;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-bottom: 13px;
    position: relative;
    & > svg {
      position: absolute;
      top: 34px;
      left: 16px;
    }

    .QM_E-tooltip_trigger {
      // visibility: hidden;
      &__error {
        visibility: visible;
        top: 32px;
        right: 16px;
        left: unset;
      }
    }
    &:hover {
      .QM_E-tooltip_trigger {
        visibility: visible;
      }
    }
  }
}