#landing__Numbers {
  background: #f2fcff;
  text-align: center;
  // color: #060935;
  color: #333543;
  h3 {
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
    &[class^="icon-"],
    &[class*=" icon-"] {
      &::before {
        font-size: 48px;
        margin-right: 0.125em;
      }
    }
  }
  strong {
    font-weight: 700;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media (min-width: 480px) {
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
    }
  }
  li {
    padding-top: 1em;
    padding-bottom: 1em;
    box-sizing: border-box;
    @media (min-width: 480px) {
      width: calc(50% - 2vw);
    }
    @media (min-width: 1024px) {
      width: 40%;
    }
    @media (min-width: 1200px) {
      width: 35%;
    }
  }
  img {
    vertical-align: middle;
    display: block;
    margin: 0 auto .5em;
    @media (min-width: 480px) {
      display: inline-block;
      margin: 0;
    }
  }
}