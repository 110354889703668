@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

#landing__FirstScreen {
  background: #020633;
		color: #fff;
		position: relative;
		padding-top: 250px;
		padding-bottom: 40px;
		@media (min-width: 680px) {
			padding-top: 160px;
		}
		.custom__commonWidth {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			h1 {
				max-width: 10em;
				padding-top: 0;
				// font-weight: 700;
			}
			p {
				max-width: 30em;
				font-size: 18px;
				padding-bottom: 0;
				a.custom__button {
					// width: auto;
					display: inline-block;
					margin-top: 1em;
					padding-left: 2em;
					padding-right: 2em;
					cursor: pointer;
					@media (max-width: 374px) {
						display: block;
						text-align: center;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
		picture,
		img {
			display: block;
		}
		picture {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
		.landing__videoContainer {
			position: absolute;
			// width: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			// height: 0;
			// padding-bottom: 56.25%;
			// padding-bottom: 40%;
		}
  ._3vVMz {
		position: absolute;
		top: 0;
		opacity: 0;
	}
}