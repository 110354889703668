@import './vars.scss';

@mixin transition {
	transition: all .15s ease-out 0s;
}
@mixin transitionColor {
	transition: color .15s ease-out 0s;
}

@mixin icomoon {
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
		
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin montserrat ($weight: 400) {
	font-family: Montserrat;
	font-weight: $weight;
}

@mixin poppins ($weight: 400) {
	font-family: Poppins;
	font-weight: $weight;
}

@mixin rubik ($weight: 400) {
	font-family: Rubik;
	font-weight: $weight;
}

@mixin text-regular {
	font-size: 14px;
  line-height: 20px;
}
@mixin gradientLink {
	position: relative;
	&::after {
		content: '';
		top: 0;
		left: -2px;
		bottom: 0;
		width: 4px;
		border-radius: 4px;
		// height: 44px;
		// background: linear-gradient(180deg, #3397AC -84.09%, rgba(51, 151, 172, 0) 117.05%);
		background: $clr-blue-light;
		position: absolute;
		opacity: 0;
		@include transition;
	}

	&:focus {
		// box-shadow: inset 0 0 0 1px $clr-blue-dark;
	}
	// &:hover,
	&:focus,
	&.active {
		&::after {
			opacity: 1;
		}
	}
	&:hover {
		background: rgba($clr-navlink-bg-hover, .4);
	}
	&:active,
	&.active {
		background: linear-gradient(180deg, #3397AC -84.09%, rgba(51, 151, 172, 0) 117.05%);
		box-shadow: none;
	}
	&.active:focus {
		// box-shadow: inset 0 0 0 1px $clr-blue-dark;
		color: $clr-white;
	}
}

@mixin uppercase {
	text-transform: uppercase;
	letter-spacing: .1em;
}

@mixin clrSchemeLight__control {
	+ label {
		white-space: nowrap;
		position: relative;
		font-size: 12px;
		margin-right: $article-padding;
		@include transitionColor;
		// &::before {
		// 	margin-left: 10px;
		// }
		span {
			display: none;
		}
	}
	&:focus + label,
	+ label:hover {
		color: $clr-text-hover;
	}
}

@mixin clrSchemeLight__blue {
	&:link,
	&:visited {
		color: $clr-text-hover;
	}
	&:hover,
	&:focus {
		color: $clr-blue-light-hover;
	}
	&:active {
		color: $clr-blue-light-active;
	}
}

@mixin customUnderline {
	text-decoration: underline;
	text-decoration-color: $clr-transparent;
	text-decoration-skip-ink: none;
	text-decoration-thickness: .1em;
	text-underline-offset: .2em;
	&,
	a & {
		@include transition;
	}
	&:hover,
	&:focus,
	a:hover &,
	a:focus & {
		text-decoration-color: currentColor;
	}
}