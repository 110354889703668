#landing__Steps {
  background: #060935;
  color: #fff;
  picture,
  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 1000px;
    max-height: 555px;
    margin-left: auto;
    margin-right: auto;
  }
}