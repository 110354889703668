.QM-pdfViewer {
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000000, 0.6);
		padding: 24px;
		height: 100vh;
		overflow: auto;
		&_content {
			height: calc(100vh - 40px);
			// overflow: auto;
		}
		&_closeBtn {
			position: fixed;
			align-self: flex-start;
			right: 24px;
			color: var(--col-white);
			&:hover {
				color: var(--col-btn-s-active);
			}
			span::before {
				font-size: 40px;
			}
		}
}
