@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.authInput {
  &-wrapper {
    .buttonAboveInput {
      position: absolute;
      bottom: 1px;
      right: 0;
      width: auto;
      min-width: 0;
      background-color: transparent;
      border: none;
      outline: none;
      @include transition;
      color: $clr-gray;
      &::before {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        font-size: 20px;
      }
      &:hover,
      &:focus-visible {
        color: $clr-dark;
      }
      &:active {
        bottom: 0;
      }
    }
  }

  &-label {
    display: flex;
		flex-direction: column;
		position: relative;
  }

  &-title {
    padding-bottom: 0.25rem;
    margin-bottom: 0;
    color: $clr-dark;
  }
  &-error {
    color: $input-error-text-color;
    position: absolute;
    top: 100%;
  }
  &-input {
    box-shadow: inset 0 0 0 1px $clr-border;
		box-shadow: inset 0 0 0 1px $clr-background-active;
    border: none;
    outline: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		border-radius: 4px;
		line-height: 2.5rem;
		color: $clr-text;
		font-weight: 500;
		@include transition;
		&::placeholder {
			color: $clr-placeholder;
		}
		&:hover {
			box-shadow: inset 0 0 0 1px $clr-placeholder;
		}
		&:focus {
			box-shadow: inset 0 0 0 1px $clr-blue-light-hover;
		}
    &__error {
      box-shadow: inset 0 0 0 1px $input-error-border-color;
    }
  }
}

