@import '/src/commonStyles/mixins.scss';

.QM-textTabs_nav {
  display: flex;
  padding-left: 1px;
  width: 100%;
}

.QM-textTab {
  @include rubik(400);
  font-size: 14px;
  background-color: var(--col-bg-2);
  color: var(--col-label-text);
  border: none;
  border-bottom: 1px solid var(--col-input-bg-disabled);
  padding-top: 20px;
  padding-bottom: 19px;
  width: inherit;
  display: flex;
  justify-content: center;
  &__selected {
    color: var(--col-btn);
    border-bottom: 1px solid var(--col-btn);
  }

}