@import '/src/commonStyles/mixins.scss';

.QM-radioSet {
  @include rubik;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;
  &__vertical {
    flex-direction: column;
  }

  &_legend {
    color: var(--col-label-text);
    margin-right: 28px;
  }
  &_label {
    margin-right: 28px;
    display: flex;
    align-items: center;
  }
  &_input {
    appearance: none;
    background-color: var(--col-dropdown-border);
    border-radius: 50%;
    margin: 0;
    font: inherit;
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-right: 13px;

    &:checked {
      border: 4px solid var(--col-btn);
    }
  }
}