.deal-info-tab {
  &__row {
    display: flex;
    margin: 24px;
		align-items: flex-end;

    .QM-input,
    .QM-input_wrapper,
    .QM-input__error {
      width: 100%;
      margin-right: 24px;
      margin-bottom: 0;

      .QM-input__error {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__textarea {
    margin: 0 24px;
    .QM-textarea,
    .QM-input_wrapper {
      // min-width: 728px;
      width: 100%;
    }
    .QM-textarea {
      height: 132px;
    }
  }
	.QM-toast {
		z-index: 2;
	}
}
