@import '/src/commonStyles/mixins.scss';

.QM-tabs {
  &_list {
    display: flex;
    width: 100%;
    &_item {
      // overflow: hidden;
      width: inherit;
    }
    &_item_link {
      @include rubik;
      font-size: 14px;
      line-height: 20px;
      position: relative;
      color: var(--col-label-text);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      white-space: nowrap;
      width: inherit;
      padding: 0 10px;
      border-bottom: 1px solid var(--col-dropdown-border);
      span {
        display: inline-block;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 0;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        background: var(--col-btn);
        @include transition;
      }
      &.active,
      &:hover,
      &:focus,
      &:active {
        &::after {
          width: 100%;
        }
      }
      &:hover,
      &:focus,
      &:active {
        color: var(--col-active-text);
      }
      &:active {
        color: (--col-active-text);
        &::after {
          background: var(--col-btn);
        }
      }
      &.active {
        color: var(--col-btn);
      }
    }
  }
}