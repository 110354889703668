.timing-tab {
  position: relative;
  margin-left: 24px;
  &:first-child {
    margin-top: 24px;
  }

  display: flex;

  .QM-input {
    padding-left: 45px;
  }

  .QM-input_wrapper {
    // min-width: 360px;
		width: 46%;
    margin-right: 24px;
  }

  .icon-calendar {
    padding: 9px 12px;
    z-index: 1;
    top: 35%;
    position: absolute;

    &__inactive {
      color: var(--col-input-border);
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
}

.timing-tab-UW-process {
  margin: 24px;

  &__title,
  &__text {
    font-size: 14px;
    color: var(--col-active-text);
  }

  &__title {
    font-weight: 500;
  }

  &__text {
    margin-top: 24px;
  }
}
