@import '/src/commonStyles/vars.scss';

.QM_E-radio {
  &_wrapper {
    display: flex;
    justify-content: space-between;
    background-color: $input-bg;
    border-radius: 8px;
    padding: 4px 6px;
    max-height: 47px;
    margin-top: 20px;
  }

  &_label {
    position: relative;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    .QM_E-tooltip_trigger {
      // visibility: hidden;
    }
    &:hover {
      .QM_E-tooltip_trigger {
        visibility: visible;
      }
    }
    .QM_E-tooltip_content {
      min-width: 100px;
    }
  }

  &_content {
    font-family: OpenSans;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding: 10px 15px;
    padding-right: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;

  }

  &_input {
    position: absolute;
    visibility: hidden;
    height: 0;
    width: 0;
    opacity: 0;

    &:checked + .QM_E-radio_content {
      background-color: $radio-checked-bg;
      color: $white;

      .QM_E-tooltip_trigger {
        stroke: $input-bg;
        opacity: 0.3;
      &:hover {
        opacity: 1;
      }
      }
    }
  }
}