@font-face {
  font-family: OpenSans;
  font-weight: 300;
  src: url("../assets/fonts/OpenSans-Light.woff2"),
    url("../assets/fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: OpenSans;
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/OpenSans-LightItalic.woff2"),
    url("../assets/fonts/OpenSans-LightItalic.ttf");
}

@font-face {
  font-family: OpenSans;
  font-weight: 400;
  src: url("../assets/fonts/OpenSans-Regular.woff2"),
    url("../assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSans;
  font-weight: 700;
  src: url("../assets/fonts/OpenSans-Bold.woff2"),
    url("../assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat-Bold.woff2"),
    url("../assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("../assets/fonts/Montserrat-SemiBold.woff2"),
    url("../assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat-Medium.woff2"),
    url("../assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("../assets/fonts/Montserrat-Regular.woff2"),
    url("../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../assets/fonts/Poppins-Bold.woff2"),
    url("../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../assets/fonts/Poppins-SemiBold.woff2"),
    url("../assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../assets/fonts/Poppins-Medium.woff2"),
    url("../assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../assets/fonts/Poppins-Regular.woff2"),
    url("../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../assets/fonts/Poppins-Light.woff2"),
    url("../assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 200;
  src: url("../assets/fonts/Poppins-ExtraLight.woff2"),
    url("../assets/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: url("../assets/fonts/Rubik-Regular.woff2"),
    url("../assets/fonts/Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: url("../assets/fonts/Rubik-Medium.woff2"),
    url("../assets/fonts/Rubik-Medium.ttf");
}

@font-face {
  font-family: Rubik;
  font-weight: 600;
  src: url("../assets/fonts/Rubik-SemiBold.woff2"),
    url("../assets/fonts/Rubik-SemiBold.ttf");
}
// Icon font
@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  // display: flex;
  display: inline-flex;
  align-items: center;

  &::before {
    @include icomoon;
    font-size: 24px;
    text-decoration: none;
  }

  &.iconOnly {
    &::before {
      padding-right: 0;
    }
    span {
      display: none;
    }
  }
  &.iconReverse {
    flex-direction: row-reverse;
  }
  &.iconIndent10 {
    &::before {
      margin-right: 10px;
    }
    &.iconReverse {
      &::before {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  &.iconSize20 {
    &::before {
      font-size: 20px;
    }
  }
}

.icon-logo .path1::before {
  content: "\e916";
  color: rgb(27, 145, 182);
}
.icon-logo .path2::before {
  content: "\e917";
  margin-left: -5.1162109375em;
  color: rgb(51, 107, 125);
}
.icon-add-deal:before {
  content: "\e953";
}
.icon-add-template:before {
  content: "\e954";
}
.icon-alert-circle1:before {
  content: "\e914";
}
.icon-archive:before {
  content: "\e955";
}
.icon-arrow-down:before {
  content: "\e933";
}
.icon-arrow-drop-down_down:before {
  content: "\e934";
}
.icon-arrow-drop-down_up:before {
  content: "\e935";
}
.icon-arrow-left:before {
  content: "\e936";
}
.icon-arrow-right:before {
  content: "\e937";
}
.icon-arrow-up:before {
  content: "\e938";
}
.icon-attention:before {
  content: "\e939";
}
.icon-book:before {
  content: "\e93a";
}
.icon-building2:before {
  content: "\e930";
}
.icon-calculator:before {
  content: "\e913";
}
.icon-calendar:before {
  content: "\e91d";
}
.icon-camera:before {
  content: "\e93b";
}
.icon-check:before {
  content: "\e92f";
}
.icon-check-circle:before {
  content: "\e93c";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e91a";
}
.icon-chevron-up:before {
  content: "\e906";
}
.icon-clock-3:before {
  content: "\e918";
}
.icon-close1:before {
  content: "\e93d";
}
.icon-coffee:before {
  content: "\e926";
}
.icon-contact:before {
  content: "\e909";
}
.icon-copy:before {
  content: "\e93e";
}
.icon-credit-card:before {
  content: "\e956";
}
.icon-distribute-evenly:before {
  content: "\e93f";
}
.icon-dollar-sign:before {
  content: "\e91e";
}
.icon-dots-horizontal:before {
  content: "\e905";
}
.icon-dots-vertical:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e919";
}
.icon-edit:before {
  content: "\e940";
}
.icon-expand:before {
  content: "\e957";
}
.icon-external-link:before {
  content: "\e922";
}
.icon-eye:before {
  content: "\e92d";
}
.icon-eye-off:before {
  content: "\e92c";
}
.icon-file:before {
  content: "\e941";
}
.icon-file-input:before {
  content: "\e92b";
}
.icon-file-output:before {
  content: "\e92a";
}
.icon-file-text:before {
  content: "\e925";
}
.icon-filter:before {
  content: "\e942";
}
.icon-folder-open:before {
  content: "\e943";
}
.icon-gauge:before {
  content: "\e912";
}
.icon-gavel:before {
  content: "\e927";
}
.icon-globe:before {
  content: "\e91c";
}
.icon-grid:before {
  content: "\e944";
}
.icon-help-circle:before {
  content: "\e931";
}
.icon-info:before {
  content: "\e945";
}
.icon-legend-bar:before {
  content: "\e946";
}
.icon-legend-dashed-line:before {
  content: "\e947";
}
.icon-legend-line:before {
  content: "\e948";
}
.icon-linkedin:before {
  content: "\e929";
}
.icon-list:before {
  content: "\e949";
}
.icon-loader:before {
  content: "\e92e";
}
.icon-lock-alt:before {
  content: "\e958";
}
.icon-mail:before {
  content: "\e94a";
}
.icon-map-pin:before {
  content: "\e923";
}
.icon-minimize:before {
  content: "\e94b";
}
.icon-minus:before {
  content: "\e902";
}
.icon-move:before {
  content: "\e94c";
}
.icon-notification:before {
  content: "\e901";
}
.icon-plus:before {
  content: "\e903";
}
.icon-portfolio:before {
  content: "\e90b";
}
.icon-printer:before {
  content: "\e94d";
}
.icon-question-circle:before {
  content: "\e95c";
}
.icon-recruiter:before {
  content: "\e94e";
}
.icon-refresh:before {
  content: "\e94f";
}
.icon-reset:before {
  content: "\e950";
}
.icon-search:before {
  content: "\e915";
}
.icon-send:before {
  content: "\e959";
}
.icon-settings:before {
  content: "\e951";
}
.icon-shield-check:before {
  content: "\e928";
}
.icon-sign_out:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
.icon-star_filled:before {
  content: "\e90e";
}
.icon-trash:before {
  content: "\e95a";
}
.icon-trending-down:before {
  content: "\e924";
}
.icon-triangle-down:before {
  content: "\e921";
}
.icon-triangle-left:before {
  content: "\e911";
}
.icon-triangle-right:before {
  content: "\e91f";
}
.icon-triangle-up:before {
  content: "\e920";
}
.icon-umbrella:before {
  content: "\e95d";
}
.icon-upload:before {
  content: "\e90a";
}
.icon-upload-cloud:before {
  content: "\e95b";
}
.icon-user:before {
  content: "\e932";
}
.icon-users:before {
  content: "\e900";
}
.icon-users2:before {
  content: "\e952";
}
.icon-weigher:before {
  content: "\e90f";
}
.icon-weigher_filled:before {
  content: "\e910";
}
.icon-x:before {
  content: "\e91b";
}
.icon-contact-book::before {
  content: "\f2b9";
}
