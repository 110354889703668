@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.user-auth {
  @include montserrat;
	background: $clr-text;
	min-height: 100vh;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
	color: $clr-text;
}
.user-auth__form-wrapper {
  background: $clr-white;
	border-radius: 6px;
  width: 100%;
	max-width: 480px;
  padding: 2rem 1.5rem;
}
.user-auth__form {
	font-size: 14px;
	width: 100%;
	
	h1 {
		font-size: 2em;
		font-weight: 500;
		text-align: center;
	}

	button[type=submit] {
		width: 100%;
		+ p {
			margin-top: 1.5rem;
		}
	}
	ul {
		padding-bottom: 3rem;
    li {
      padding-top: 1.5rem;
    }
		.r {
			margin-bottom: -1.5rem;
		}
	}
	a {
		@include clrSchemeLight__blue;
		font-weight: 600;
		@include customUnderline;
	}
  .c {
    text-align: center;
    margin-top: 5px;
  }
	small {
		font-size: 12px;
	}
}

.user-auth__btn {
  @include montserrat(600);
  border: none;
  background: $clr-text-hover;
  font-size: 14px;
  line-height: 3em;
  height: 3em;
  overflow: hidden;
  padding: 0 1em;
  display: inline-block;
  border-radius: 4px;
  min-width: 10em;
  @include transition;
  &,
  &:link,
  &:visited,
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: $clr-white;
  }
  &:hover,
  &:focus {
    background: $clr-blue-light-hover;
  }
  &:active {
    line-height: calc(3em + 1px);
    background: $clr-blue-light-active;
  }
}
