@import '/src/commonStyles/vars.scss';

.QM_E-errorTile {
  background-color: $input-error-text-color;
  box-shadow: 0px 4px 12px rgba(15, 33, 63, 0.55);;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &_text {
    font: {
      family: OpenSans;
      weight: 400;
      size: 14px;
    }
    line-height: 24px;
    color: $white;
  }

  &_icon {
    margin-left: 10px;
    stroke: $white;
    opacity: 1;
    cursor: pointer;
    &:hover{
      stroke: $white;
      stroke-width: 2;
    }
  }
}