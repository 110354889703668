@import "/src/commonStyles/mixins.scss";

.exposure-tab {
	padding-top: 15px;

	.exposure-table {
		table-layout: fixed;
		width: 100%;
		background-color: var(--col-bg-2);
		thead {
			box-shadow: inset 0px -1px 0px #ebeff2;
	
			th {
				padding: 16px 0;
				text-align: start;
				color: var(--col-label-text);
				font-size: 12px;
				line-height: 18px;
				@include rubik(500);
	
				&:first-child {
					padding-left: 24px;
				}
				&:last-child {
					padding-right: 24px;
				}
			}
		}
		tbody {
			tr {
				position: relative;
				box-shadow: inset 0px -2px 2px #ebeff2;

				&:hover {
					background-color: var(--col-table-row-hover);
				}
				td {
					vertical-align: middle;
					padding: 16px 0;
		
					&:first-child {
						padding-left: 24px;
					}
					&:last-child {
						padding-right: 24px;
					}
					&.QM-table_cell__headlike {
						@include rubik;
						@include text-regular;
						color: var(--col-label-text);
					}
					p {
						@include rubik;
						font-size: 14px;
						line-height: 20px;
						color: var(--col-active-text);
					}
				}
			}
		}
	}
}
