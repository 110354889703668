@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.authModal {
  background: $clr-white;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	border-radius: 4px;
	padding: 24px 48px;
	text-align: center;
	z-index: 3;
	font-size: 14px;
	width: calc(100% - 3rem);
	max-width: 432px;
  border: none;
  margin: 0;
  &[class^="icon-"],
	&[class*=" icon-"] {
		flex-direction: column;
		&::before {
			display: block;
			color: $clr-text-hover;
			background: $clr-blue-pale;
			padding: .75em;
			border-radius: 100%;
			margin-bottom: .8em;
      animation: rotate 2s linear infinite;
		}
	}
  &::backdrop {
    background: rgba(#48484A,.6);
    backdrop-filter: blur(3px);
  }

  .icon-x {
		position: absolute;
		top: .5em;
		right: .5em;
		overflow: hidden;
		opacity: .33;
    background-color: transparent;
    border: none;
		@include transition;
		&::before {
			width: 1.5em;
			height: 1.5em;
			line-height: 1.5em;
		}
		&:hover,
		&:focus {
			opacity: .66;
		}
		&:active {
			opacity: 1;
			&::before {
				transform: translateY(1px);
			}
		}
	}
  &-curtain {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#48484A,.6);
    backdrop-filter: blur(3px);
  }

  h3 {
		font-size: 20px;
		font-weight: 500;
	}
	p {
		padding-top: 1em;
	}
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}