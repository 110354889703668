@import "/src/commonStyles/mixins.scss";

.QM-brokerPortal {
  min-height: 100vh;
  h2 {
    @include rubik;
    font-size: 20px;
    line-height: 28px;
  }

  .QM-newAction-btn {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 46px;
    width: calc(100% - 24px);
  }
}
