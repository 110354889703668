@import "/src/commonStyles/mixins.scss";

.QM-newDeal {
  overflow: auto;
  max-height: 100vh;
  padding: 14px;
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  .QM-formBlock {
    padding: 24px 0;

    &__title {
      font-size: 14px;
      font-weight: 500;
      padding: 28px 0;
    }
  }
  .QM-formRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 14px;
    &__soloItem {
      display: flex;
      flex-wrap: wrap;
    }

    .new_deal_file_dropzone {
      min-width: 250px;
      height: 45px;
      @include rubik;

			&__spa {
				@media screen and (min-width: 1184px) {
					margin-top: -21px;
				}
			}

      &_wrapper {
        border: 2px dashed var(--col-btn-s-active);
        border-radius: 6px;
        position: relative;
        height: 100%;

        &__dragActive {
          background-color: var(--col-dropzone-bg);
          border-radius: 6px;
          opacity: 0.7;
        }
      }

      button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &_files {
        margin-top: 46px;
				display: flex;
				align-items: baseline;
				.filename_tooltip {
					word-wrap: break-word;
					max-width: 300px;
				}
				p {
					// max-width: 250px;
					overflow: hidden;
					white-space: nowrap;
        	text-overflow: ellipsis;
				}

				&__spa {
					@media screen and (min-width: 1184px) {
					margin-top: 27px;
					}
				}
      }

      &_delete {
        position: relative;
        top: 5px;
        left: 8px;
        color: var(--col-label-text);
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;

        &:hover {
          color: var(--col-status-success);
        }
      }
    }
  }

  .QM-btnsFormRow {
    display: flex;
    justify-content: space-between;
    margin-top: 46px;
  }
  .newDeal-submit-btn {
    min-width: 252px;
  }
  .QM-input_wrapper {
    min-width: 250px;
    margin-right: 0;
  }
  .QM-toast {
    transform: translateX(-50%);
  }

  &_confirmModal {
    max-width: 500px;
  }
  .QM-loader-wrapper {
    z-index: 1100;
  }
}
