.QM-modal {
  &_bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000000, 0.6);
  }
  &_content {
    padding: 42px 50px;
    background-color: var(--col-bg-2);
    box-shadow: inset 1px 0px 0px #ebeff2;
    &__small {
      padding: 24px;
      max-width: 400px;
    }
		&__medium {
			padding: 32px;
			max-width: 800px;
		}
  }
}
