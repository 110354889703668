@import "/src/commonStyles/mixins.scss";

.QM-dealPage {
  @include rubik;
  padding-left: 0;
  padding: 14px 24px;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  background-color: var(--col-bg-1);
  margin-left: var(--aside-width-neg);

  .QM-toast {
    position: fixed;
    transform: translateX(-50%);
    // add bottom after save btn is added
  }
}
