
@import '/src/commonStyles/vars.scss';

.QM_E-helpTile {
  background-color: $estimator-chart-helper-bg;
  display: flex;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: $estimator-chart-helper-bg-hover;
  }

  &_icon {
    stroke: $white;
    opacity: 1;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
  }
  &_text {
    color: $white;
    font-family: OpenSans;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
}