@import '/src/commonStyles/vars.scss';

.QM_E-btn {
  font: {
    family: OpenSans;
    weight: 700;
    size: 13px;
  }
  line-height: 18px;
  text-align: center;

  width: 218px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  outline: none;
  & > svg {
    margin-right: 10px;
  }
  &__primary {
    border: 1px solid $primary-btn-color;
    background-color: $primary-btn-color;
    // box-shadow: $primary-btn-shadow;
    color: $white;
    &:hover {
      // background-color: $primary-btn-color-hover;
      // border: 1px solid $primary-btn-color-hover;
      box-shadow: $primary-btn-shadow-hover;
    }
  }

  &__secondary {
    border: 1px solid $primary-btn-color;
    background-color: transparent;
    color: $primary-btn-color;

    &:hover {
      border: 1px solid $primary-btn-color-hover;
      box-shadow: $primary-btn-shadow-hover;
    }
  }
}