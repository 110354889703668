.rw-insurance {
  margin: 24px;

  &__form {
    margin-bottom: 48px;
  }

  &__retention-title,
  &__duration-title,
  &__exclusions-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--col-active-text);
  }

  &__retention-list {
    display: flex;
    margin-top: 24px;
  }

  &__retention-text,
  &__duration-text {
    margin-left: 24px;
  }

  &__retention-text,
  &__duration-text,
  &__exclusions-text {
    // min-width: 352px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;

    color: var(--col-label-text);
  }

  &__duration-list {
    display: flex;
    margin-top: 24px;

    section:last-child {
      width: 48.5%;
    }
  }

  &__retention-item {
    // min-width: 352px;
    width: 100%;
  }

  .QM-input,
  .QM-input_wrapper,
  .QM-input__error {
    // min-width: 352px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }

  .QM-input_wrapper {
    margin-bottom: 12px;
  }

  &__textarea {
    .QM-textarea,
    .QM-input_wrapper {
      // min-width: 728px;
      width: 100%;
    }
    .QM-textarea {
      height: 132px;
    }
  }
}

.insurance-compliance {
  // min-width: 780px;
  margin: 24px;

  &__header {
		width: 100%;
    display: grid;
    // grid-template-columns: 1.1fr 0.7fr 0.9fr 0.9fr;
		grid-template-columns: 1fr 75px 1fr 1fr;
		gap: 12px;
    align-items: center;
    font-size: 12px;
    color: var(--col-label-text);
  }

  &__form {
		width: 100%;
		display: grid;
    // grid-template-columns: 1.1fr 0.7fr 0.9fr 0.9fr;
		grid-template-columns: 1fr 75px 1fr 1fr;
		gap: 12px;
    align-items: center;
    font-size: 14px;
    padding-top: 12px;

    .QM-input_wrapper {
      margin-bottom: 0;
			margin: 0;
			width: 100%;
			min-width: 100px;
    }

    .QM-checkbox:checked + .QM-checkbox_label::after {
      top: 2px;
    }
  }
}
