@import '/src/commonStyles/mixins.scss';

.QM-actionDialog {
  @include rubik;
  position: fixed;
  top: 0;
  padding: 28px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  background-color: var(--col-bg-2);
  // width: calc(100% - var(--aside-width));
	min-width: 400px;
  // min-height: 289px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
	z-index: 2;

  &::before {
    content: '';
    position: absolute;
    display: block;    
    width: 0px;        
    left: 0;
    top: 115px;
    border: 7px solid transparent;
    border-left: 0;
    border-right: 7px solid var(--col-bg-2);
    transform: translate(-100%, -50%);
  }
  .QM-actionDialog_closeBtn {
    position: absolute;
    padding: 0;
    right: 9px;
    top: 9px;
  }
  &_title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
    white-space: nowrap;
  }
  &_section {
    margin-right: 90px;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &_list{
    &_action {
      padding: 12px;
      cursor: pointer;

      &:hover {
        background-color: var(--col-btn-s-hover);
      }
      &:active {
        background-color: var(--col-btn-s-active);
      }
    }
  }
}
