@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-userBlock {
  @include rubik;
  position: relative;
  display: flex;
  justify-content: space-between;
  &::before {
    position: absolute;
    width: 100%;
    content: '';
    display: block;
    border-top: 1px solid var(--col-input-border);
    opacity: .2;
  }

  a:link.QM-userBlock_summary {
    color: var(--col-dark-inactive-text);
    &:hover {
      color: var(--col-white);
    }
  }
  &_summary {
    display: flex;
    align-items: center;
    padding: 12px;
    
    strong,
    small {
			display: block;
			max-width: 168px;
			overflow: hidden;
			text-overflow: ellipsis;
    }
    strong {
        font-size: 14px;
    }
    small {
        font-size: 11px;
    }
  }
  &_avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: contain;
    margin-right: 8px;
  }

  &_btn {
    border: none;
    background: transparent;
    color: var(--col-dark-inactive-text);
    // padding: 0 19px;
    // outline: none;
    &::before {
      width: 20px;
      height: 20px;
    }
    &:hover {
      color: var(--col-white)
    }
  }

  &_dropdown {
    position: absolute;
    right: 12px;
    bottom: 80%;
    width: 135px;
    z-index: 2;
  }
}
