@import "/src/commonStyles/mixins.scss";
.QM-kvBlock {
  @include rubik;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 12px;
  color: var(--col-active-text);
  &__clickable {
    cursor: pointer;
    &:hover {
      background-color: var(--col-table-row-hover);
    }
    &:active {
      background-color: var(--col-btn-s-hover);
    }
  }
  strong {
    font-size: 12px;
    color: var(--col-label-text);
    margin-right: 12px;
    line-height: 18px;
  }
}
