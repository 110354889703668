:root {
  --col-white: #ffffff;
  --col-active-text: #1a1a36;
  --col-label-text: #818e9c;
  --col-inactive-text: #c9d1d9;
  --col-dark-inactive-text: #989bb2;
  --col-clr-border: #ebeff2;
  --col-stakeholders-border: #eff0f1;

  --col-input-border: #c9d1d9;
  --col-dropdown-border: #ebf1f5;
  --col-input-bg-disabled: #f1f7f9;
  --col-list-row-hover: #f0f9ff;
  --col-table-row-hover: #f9fafb;

  --col-bg-1: #f8fbfc;
  --col-bg-2: var(--col-white);
  --col-dropzone-bg: #d1e9f0;
  --col-btn: #1b91b6;
  --col-btn-p-hover: #167593;
  --col-btn-p-active: #326676;
  --col-btn-p-disabled: var(--col-inactive-text);

  --col-btn-s-hover: #e9f7fc;
  --col-btn-s-active: #a6e0f2;
  --col-btn-s-disabled: #f8fbfb;

  --col-status-success: #00a6a9;
  --col-status-warning: #f5b95f;
  --col-status-error: #e05a5c;
  --col-status-error-light1: #ffb3b4;
  --col-status-error-light2: #ffe6e6;
	--col-status-error-dark: #c13e40;

  --shape-radius: 4px;
  --aside-width: 264px;
  --aside-width-neg: -264px;
  --contacts-headers-height: 186px;
  --submission-headers-height: 129px;
}
