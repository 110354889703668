@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-commonHeader {
  @include poppins(200);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 680px) {
      flex-direction: row;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    li {
      margin-top: 2em;
      &:last-child {
        order: 3;
      }
      &:first-child {
        order: 2;
      }
    }

    @media (min-width: 680px) {
      flex-direction: row;
      li {
        margin-right: 20px;
        margin-top: 0;
        &:last-child {
          margin-right: 0;
          order: 0;
        }
        &:first-child {
          order: 0;
        }
      }
      // li + li {
      // 	margin-top: 0;
      // }
    }

    .navLink {
      color: $clr-white;
      &:hover {
        color: $clr-text-hover;
      }
      &__active {
        color: $clr-blue-light;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
  img {
    display: block;
  }

  .QM-logo {
    margin: 0;
  }
}