@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-navMenuItem {
  position: relative;

  &_link {
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active,
    &__active {
      color: $clr-white;
    }
    &__inner {
      padding-left: 60px;
    }

    display: block;
    width: 100%;
    line-height: 56px;
    white-space: nowrap;
    padding-left: 14px;
    padding-right: 32px;
    position: relative;
    z-index: 1;
    @include gradientLink;
    @include rubik(500);
    &::before {
      margin-right: 16px;
    }
  }
  &_details {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 8px;
      height: 56px;
      // background: red;
      top: 0;
      right: 56px;
      z-index: 1;
      transform: translateY(-100%);
      pointer-events: none;
    }

    &_trigger {
      &[class^="icon-"],
      &[class*=" icon-"] {
        position: absolute;
        width: 56px;
        height: 56px;
        display: block;
        overflow: hidden;
        z-index: 1;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        cursor: pointer;
        display: block;
        color: $clr-white;
        @include transition;
        &::before {
          width: 20px;
          height: 56px;
          line-height: 56px;
          display: block;
          text-align: center;
          margin-left: 19px;
        }
        &:hover {
          color: $clr-text-hover;
        }
        &:focus-visible {
          box-shadow: inset 0 0 0 1px $clr-blue-dark;
        }
        &:active {
          color: $clr-green;
        }
      }
    }
  }
}
