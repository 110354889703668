.QM-table.QM-table_partnerDeals {
	tbody td:last-child {
		max-width: none;
	}
	.QM-btn__decline {
		background-color: var(--col-status-error);
		border-color:  var(--col-status-error);
		&:hover {
			background-color: var(--col-status-error-dark);
			border-color:  var(--col-status-error-dark);
		}
	}
}
