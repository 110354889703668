@import "/src/commonStyles/mixins.scss";

.QM-autocomplete {
	position: relative;
	&_results {
		width: 100%;
		max-height: 300px;
    overflow: auto;
		background-color: var(--col-white);
		border-radius: var(--shape-radius);
		border: 1px solid var(--col-dropdown-border);
		box-shadow: 0 2px 8px 1px rgba(0, 0, 0, .15);
		position: absolute;
		z-index: 10;
		top: 68px;

		&_item {
			@include rubik;
			@include text-regular;
			padding: 8px 16px;

			cursor: pointer;
			&:hover {
				background-color: var(--col-list-row-hover);
			}
		}
	}
	&_loaderContainer {
		position: absolute;
		bottom: 32px;
    right: 12px;
	}
}
