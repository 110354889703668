$estimator-bg-gradient: 
  linear-gradient(to right,  transparent 60%, rgba(6, 43, 99, 0.2) 100%),
  linear-gradient(180deg, #6098d1 0%, #9ac4e7 587px, #3d77aa 588px, #204076 100%);

$estimator-fallback-bg:     #3d77aa;
$white:                     #ffffff;

$input-bg:                  #F1F9FF;
$input-option-hover-bg:     #D3E5F5;

$input-text-color:          #333333;
$input-error-text-color:    #B50101;
$input-placeholder-color:   #A1B4C6;

$input-spinner-icon-color:  #269AD4;

$input-focus-border-color:  #98C2E6;
$input-error-border-color:  #EA0000;

$radio-checked-bg:          #629AD2;

$input-focus-shadow:  0px 0px 16px rgba(40, 79, 113, 0.4);


$primary-btn-color:       #1EC6C4;
$primary-btn-color-hover: #25afad;
$primary-btn-shadow: 0px 4px 10px rgba(62, 252, 252, 0.24);
$primary-btn-shadow-hover: 0px 4px 15px rgba(62, 252, 252, 0.24);

$estimator-chart-card-bg-fallback: #204076;
$estimator-chart-card-bg: linear-gradient(224.88deg, #6098D1 -18.94%, #204076 116.74%);
$estimator-chart-card-shadow: 0px 4px 12px rgba(15, 33, 63, 0.4);
$estimator-chart-card-shadow-hover: 0px 4px 20px rgba(15, 33, 63, 0.85);

$estimator-chart-text-color1:     #3EFCFC;
$estimator-chart-text-color2:     #203F73;

$estimator-chart-helper-bg:       #214278;
$estimator-chart-helper-bg-hover: #2F5EAB;

// DASHBOARDS

// +palette
/// +from Figma
$clr-dark:					    #686F7B;
$clr-gray:					    #A3AEC1;
$clr-super-light-gray:	#FAFAFA;
$clr-green:				      #1EC6C4;
$clr-blue-pale:			    #F0F9FF;
/// +from Figma

$clr-black:				      #000;
$clr-text:					    #333543;
$clr-placeholder:			    $clr-gray;
$clr-background-active:	#D1D1D6;
$clr-border:				    #EBEFF2;
$clr-background:			  #F9FAFB;
$clr-background-hover:		$clr-super-light-gray;
$clr-white:				      #FFF;

$clr-blue-dark-H:			    195;
$clr-blue-dark-S:         42%;
$clr-blue-dark-L:			    35%;
$clr-blue-dark:			      hsl($clr-blue-dark-H, $clr-blue-dark-S, $clr-blue-dark-L);
$clr-blue-dark-hover:		  hsl($clr-blue-dark-H, $clr-blue-dark-S, calc($clr-blue-dark-L + 5%));
$clr-blue-dark-active:		hsl($clr-blue-dark-H, $clr-blue-dark-S, calc($clr-blue-dark-L + 10%));

$clr-blue-light-H:		    194;
$clr-blue-light-S:        74%;
$clr-blue-light-L:			  41%;
$clr-blue-light:			    hsl($clr-blue-light-H, $clr-blue-light-S, $clr-blue-light-L);
$clr-blue-light-hover:		hsl($clr-blue-light-H, $clr-blue-light-S, calc($clr-blue-light-L + 5%));
$clr-blue-light-active:	  hsl($clr-blue-light-H,$clr-blue-light-S, calc($clr-blue-light-L + 10%));

// $clr-text-hover:		#4285f4;
// $clr-text-hover:		#336B7D;
$clr-text-hover:			    #1B91B6;
$clr-navlink-bg-hover:    #326676;


$clr-transparent:			    rgba(255,255,255,0);

// $color:					0, 100%; /*the base color*/
// $l:						50%; /*the initial lightness*/

// $clr-blue-dark: hsl(var($color),var($l));
// $color-primary-darker: hsl(var($color),calc(var($l) - 5%));
// $color-primary-darkest: hsl(var($color),calc(var($l) - 10%));

/// +tailor suite
$clr-DUNS:					#C96000;
$clr-Cyber:				  #1B91B6;
$clr-QScore:				#D5C49F;
$clr-Office:				#336B7D;
$clr-Assets:				#1EC6C4;
/// -tailor suite
// -palette

$aside:					  264px;
$article-padding:	36px;
