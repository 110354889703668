.QM_tooltip {
  &_content {
    font: {
      family: OpenSans;
      weight: 400;
      size: 12px;
    }
    line-height: 18px;
    color: var(--col-active-text);

    width: 340px;
    overflow: auto;

    &__error {
      min-width: fit-content;
    }
  }

  &_trigger {
    margin-left: 4px;
    cursor: pointer;
    opacity: 0.3;
    stroke: var(--col-active-text);
    &:hover {
      opacity: 1;
    }

    &__light {
      stroke: var(--col-white);
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }

    &__error {
      stroke: var(--col-status-error);
      visibility: visible;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
