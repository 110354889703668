@import '/src/commonStyles/vars.scss';
@import '/src/commonStyles/mixins.scss';

.QM-logo {
	display: block;
	width: 219.31px;
	height: 41.6px;
	overflow: hidden;
	position: relative;
	margin: 16px auto 36px;
	
	&::before,
	&::after {
		@include transition;
		@include icomoon;
		display: block;
		width: 219.31px;
		height: 41.6px;
		font-size: 41.6px;
	}
	&::before {
		content: "\e917";
		color: $clr-blue-dark;
	}
	&::after {
		content: "\e916";
		color: $clr-blue-light;
		top: 0;
		left: 0;
		position: absolute;
	}
	&:hover,
	&:focus {
		&::before {
			color: $clr-blue-dark-hover;
		}
		&::after {
			color: $clr-blue-light-hover;
		}
	}
	&:active {
		&::before {
			color: $clr-blue-dark-active;
		}
		&::after {
			color: $clr-blue-light-active;
		}
	}

	&__darkMode {
		box-shadow: 0 0 0 300vmax $clr-text, inset 0 0 0 300vmax $clr-text;
	}
}