@import "/src/commonStyles/mixins.scss";

.QM-btn {
  @include rubik(500);
  font-size: 14px;
  border-radius: var(--shape-radius);
  border: 1px solid var(--col-btn);
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  span[class^="icon-"] + span {
    margin-left: 11px;
  }
  &__reverse {
    flex-direction: row-reverse;
    span[class^="icon-"] + span {
      margin-right: 11px;
      margin-left: 0;
    }
  }
  &__primary {
    background-color: var(--col-btn);
    color: var(--col-white);
    &:hover {
      background-color: var(--col-btn-p-hover);
      border-color: var(--col-btn-p-hover);
    }
    &:active {
      background-color: var(--col-btn-p-active);
      border-color: var(--col-btn-p-active);
    }
    &:disabled {
      background-color: var(--col-btn-p-disabled);
      border-color: var(--col-btn-p-disabled);
    }
  }
  &__secondary,
  &__tile {
    background-color: var(--col-bg-2);
    color: var(--col-btn);
    &:hover {
      background-color: var(--col-btn-s-hover);
    }
    &:active {
      background-color: var(--col-btn-s-active);
    }
    &:disabled {
      background-color: var(--col-btn-s-disabled);
      border-color: var(--col-btn-s-disabled);
    }
  }

  &__transparent {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--col-active-text);
		&:hover {
      color: var(--col-btn);
    }
    &:active {
      color: var(--col-btn-p-active);
    }
  }
  &__tile {
    flex-direction: column;
    span[class^="icon-"] + span {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
