.QM-acceptDeclineBlock {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 8px;
}

.QM-manageInvitationModal {
	header, footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	header {
		margin-bottom: 32px;
	}
	& &_body {
		margin-bottom: 32px;

		&_text {
			max-width: 400px;
		}
	}

	.QM-checkbox {
		&_wrapper {
			margin-bottom: 8px;
		}
		&_label {
			display: flex;
			align-items: flex-start;
		}
	}
}
