@import "/src/commonStyles/mixins.scss";

.QM-dealContactsForm {
  padding: 24px;
  // overflow-y: auto;
  // height: calc(100vh - 150px); // 150px - temporary header height
  .QM-formBlock {
    margin-bottom: 20px;

    legend {
      margin-bottom: 20px;
      p {
        @include text-regular;
        font-weight: 500;
        color: var(--col-active-text);
        margin-bottom: 20px;
      }
    }

    &_legend__flex {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &_checkbox {
      display: flex;
      font-size: 14px;

      .QM-checkbox_wrapper {
        margin-right: 12px;
      }

      .QM-checkbox:checked + .QM-checkbox_label::after {
        top: 2px;
      }

      span {
        margin-top: 3px;
      }
    }

    &_p-c-broker-block {
      .QM-checkbox_wrapper {
        margin-bottom: 20px;
      }
    }

    &_p-c-broker {
      margin-right: 10px;
    }

    .QM-btn__remove {
      color: var(--col-status-error);
      font-size: 20px;
      padding: 10px;
      &:hover {
        background-color: var(--col-status-error-light2);
      }
      &:active {
        background-color: var(--col-status-error-light1);
      }
    }

    & > .QM-radioSet {
      margin-bottom: 24px;
    }
    .QM-dueDilligenceCount {
      width: 100%;
      .QM-input {
        width: calc(50% - 12px);
      }
    }
    &_row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      .QM-input_wrapper {
        width: 100%;
      }
    }
  }
  .QM-btnRow {
    display: flex;
    justify-content: flex-end;
  }

	.delete-modal {
		h2 {
      margin-bottom: 8px;
			word-break: break-word;
    }
    &_btnsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
	}
}
