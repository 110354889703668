.QM-dropdownMenu {
  background-color: var(--col-bg-2);
  border-radius: var(--shape-radius);
  border: 1px solid var(--col-dropdown-border);
  &_item {
    background-color: var(--col-bg-2);
    cursor: pointer;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    min-width: max-content; //temp

    &:hover {
      background-color: var(--col-list-row-hover);
    }

    span[class^="icon-"] {
      margin-right: 8px;
      &::before {
        font-size: 20px;
      }
    }
  }
}