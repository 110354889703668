.underwriting-tab {
  .QM-tile {
    margin-top: 14px;
  }

  &__header {
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 500;
    color: var(--col-label-text);
    line-height: 20px;

    &-info > span:first-child {
      margin-right: 50px;
    }

    &-choosed > span:first-child {
      margin-right: 24px;
    }
  }

  &__header-text,
  &__header-choosed-text {
    position: relative;
    top: 23%;
  }

  &__header-choosed {
    display: flex;

    &-text {
      font-weight: 400;
    }
  }

  .QM-table_scroller {
    height: calc(100vh - 24px);
    overflow: auto;

    thead tr th {
      position: sticky;
      top: 0;
      box-shadow: inset 0px -1px 0px var(--col-clr-border);
      background-color: var(--col-bg-2);
      z-index: 1;
			padding: 16px 8px;
			&:first-child {
				padding-left: 24px;
			}
			&:last-child {
				min-width: 100px;
			}
      .QM-checkbox:checked + .QM-checkbox_label::after {
        top: 1px;
      }
    }

    td {
      font-size: 14px;
      color: var(--col-active-text);
      line-height: 20px;
			min-width: 60px;
			// max-width: unset;
			padding: 16px 8px;
			strong {
				overflow: auto;
				white-space: normal;
				text-overflow: unset;
				word-wrap: break-word;
			}
    }

    .QM-table_openLinkBtn {
      visibility: visible;
      border: 1px solid var(--col-btn);
      color: var(--col-btn);
      padding: 10px 16px;

      &:hover:not([disabled]) {
        color: var(--col-white);
        background-color: var(--col-btn);
      }

      &:disabled,
      [disabled] {
        border: 1px solid var(--col-input-border);
        color: var(--col-input-border);
      }
    }

    .QM-table {
      tbody td:first-child {
        min-width: 50px;
      }
      .QM-table_row__selected {
        background-color: var(--col-btn-s-hover);
        &::after {
          background: linear-gradient(to bottom, transparent, #f0f1f2);
        }
      }
    }

    .QM-checkbox_wrapper {
      margin-top: -3px;
    }

    .QM-checkbox:checked + .QM-checkbox_label::after {
      top: 2px;
    }
  }

  .QM-modal {
    &_bg {
      position: fixed;
      z-index: 100000;
    }

    &_content.underwritingModal {
      border-radius: 4px;
      padding: 24px;
    }
  }

  .deal-page-modal {
    max-width: 545px;
    &__body {
      max-width: 475px;
    }

    &__footer {
      .tabs-submit__btn {
        position: static;
        left: 0;
        transform: translateX(0);
      }

      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }

    &__title,
    &__text,
    &__subtext {
      font-weight: 400;
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__text,
    &__subtext {
      font-size: 14px;
      line-height: 20px;
    }

    &__subtext {
      margin-top: 14px;
    }
  }
}
