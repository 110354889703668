.QM-deals__empty {
  display: flex;
  flex-direction: column;
  max-width: 235px;
  margin: auto;
  padding: 40px 0;

  h2 {
    margin-bottom: 42px;
  }
}