@import "/src/commonStyles/vars.scss";
@import "/src/commonStyles/mixins.scss";

.QM-globalSearch {
  &_form {
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--col-white);
		flex-basis: 650px;

    .icon-search {
      position: relative;
      display: flex;
      &::before {
        color: var(--col-label-text);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        left: 14px;
        @include transition;
      }

      &:focus-within {
        &::before {
          color: var(--col-active-text);
        }
      }

      &.QM-globalSearch_label__isLoading {
        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          border: 4px solid $clr-text;
          border-left-color: $clr-placeholder;
          top: calc(50% - 14px);
          right: $article-padding;
          // transform: translateY(-50%);
          border-radius: 100%;
          animation: rotateIn 1s infinite linear both;
          opacity: 0.5;
        }
      }
    }
  }

  &_field {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 48px;
    border: 1px solid var(--col-label-text);
    border-radius: 4px;
    color: var(--col-active-text);
    outline: none;
    @include rubik;

    &::placeholder {
      color: var(--col-label-text);
    }
    &:focus-within {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      border: 1px solid var(--col-btn);
    }
  }
  &_resultList {
    box-shadow: 0 2px 4px rgba(#5d6164, 0.15);
    position: absolute;
    background: var(--col-white);
    width: 100%;
		max-height: 300px;
    overflow: auto;
    left: 0;
    right: 0;
  }
	&_emptyResult {
		color: var(--col-label-text);
		padding: 16px;
	}
}
