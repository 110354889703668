.broker-sidebarMenu {
	height: calc(100vh - 234px); //178px - logo & btn, 56px - footer
	overflow: auto;
	&::-webkit-scrollbar-track {
		background: #333543;
	}
	&::-webkit-scrollbar-thumb {
		background: #4e5063;
		&:hover {
			background: #5d5f6d;
		}
}
}
