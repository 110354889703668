@import "/src/commonStyles/mixins.scss";

.QM-treeItem {
  &_row_small {
    min-width: 350px;
		@media screen and (max-width: 1130px) {
			min-width: 350px;
		}
  }

  &_row_big {
    min-width: 500px;
		@media screen and (max-width: 1130px) {
			min-width: 400px;
		}
  }

  &_row,
  &_row_small,
  &_row_big {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px dashed transparent;
    padding: 15px;
    @include rubik;
    @include text-regular;
   

    &__dragActive {
      background-color: var(--col-dropzone-bg);
      border-radius: 6px;
      border: 2px dashed var(--col-btn-s-active);
      opacity: 0.7;
    }
    &_main {
      display: flex;
      align-items: center;
	  margin-right: 8px;
	  cursor: pointer;
      span {
        color: var(--col-inactive-text);
        margin-right: 8px;
        &[class^="icon-triangle"] {
          cursor: pointer;
        }
      }
    }

    &_actionsContainer {
      display: none;
    }

    &:hover {
      .QM-treeItem_row_main {
        span {
          color: var(--col-active-text);
        }
      }
      .QM-treeItem_row_actionsContainer {
        display: block;
      }
    }
  }
  &_inner {
    padding-left: 25px;
  }
}

.QM-btn__transparent {
  color: var(--col-label-text);
  padding: 0;
  border: none;
}
.QM-fileItem {
  &_row {
    padding-left: 50px;
    &_main {
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
				max-width: 280px;
				@media screen and (max-width: 1130px){
					max-width: 200px;
				}
      }
    }

    &_actionsContainer {
      display: flex;
      align-items: center;

      &_info {
        @include rubik;
        font-size: 12px;
        color: var(--col-label-text);
        margin-right: 8px;
        white-space: nowrap;
        &:last-child {
          margin-right: 8px;
        }

        &__hidden {
          display: none;
        }
				@media screen and (max-width: 1130px){
        	display: none;
				}
      }
    }
    .QM-btn__transparent {
      display: none;
      height: 24px;
    }
    &:hover {
      .QM-fileItem_row_main p {
				max-width: 200px;
				@media screen and (max-width: 1130px){
					max-width: 150px;
				}
      }
      .QM-fileItem_row_actionsContainer_info__hidden {
				@media screen and (min-width: 1131px){
        	display: block;
				}
      }
      .QM-btn__transparent {
        display: block;
      }
    }
  }

	.QM_tooltip_content {
		.QM-kvBlock {
			align-items: baseline;
			strong {
				width: 70px;
			}
			span {
				word-wrap: break-word;
				width: calc(100% - 70px);
			}
		}
	}

  .fileDelete-modal {
    h2 {
      margin-bottom: 8px;
			word-break: break-word;
    }
    &_btnsContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
}
