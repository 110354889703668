@import '/src/commonStyles/mixins.scss';

.QM-toast {
  @include rubik;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(calc(-50% - 150px));
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: var(--shape-radius);
  width: 566px;
  padding: 12px;
  display: flex;
  justify-content: space-between;

  & > [class^="icon-"]::before {
    font-size: 32px;
    margin-right: 16px;
  }
  &__info {
    background-color: var(--col-bg-2);
  }
  &__success {
    background-color: var(--col-status-success);
    color: var(--col-white);
    .QM-btn {
      color: var(--col-white);
    }
  }
  &__warning {
    background-color: var(--col-status-warning);
    color: var(--col-white);
    .QM-btn {
      color: var(--col-white);
    }
  }
  &__error {
    background-color: var(--col-status-error);
    color: var(--col-white);
    .QM-btn {
      color: var(--col-white);
    }
  }
}