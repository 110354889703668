@import '/src/commonStyles/mixins.scss';

#FormEstimatorShort {
  background: #060935;
  h2 {
    color: #fff;
  }
  form {
    background: #F1FBFF;
    border-radius: 4px;
    padding: 40px 20px;
    font-weight: 400;
    color: #070933;
    max-width: 432px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 375px) {
      padding: 40px;
    }
    legend {
      font-size: 20px;
      line-height: 1.2;
      color: #000;
      font-weight: 700;
      padding-bottom: 40px;
    }
    span {
      display: block;
      font-size: 12px;
      padding-bottom: 4px;
      line-height: 1.25;
      &::before {
        position: absolute;
        font-size: 24px;
        width: 46px;
        height: 46px;
        line-height: 46px;
        color: #269AD4;
        left: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        text-align: center;
      }
      &[class^="icon-"],
      &[class*=" icon-"] {
        + input {
          padding-left: 46px;
        }
        ~ .QM_E-tooltip_trigger {
          position: absolute;
          top: 30px;
          left: calc(100% - 43px);
        }

        &.icon-globe,
        &.icon-search {
          + input {
            max-width: 100%;
          }
        }
      }
    }
  }
  label {
    position: relative;
    display: block;
    .QM_E {
      position: absolute;
      top: 30px;
      right: 16px;
    }
  }
  input {
    display: block;
    color: #070933;
    background: #fff;
    border-radius: 4px;
    border: 0;
    font-size: 14px;
    height: 46px;
    line-height: 46px;
    padding: 0 16px;
    margin: 0;
    width: 100%;
    max-width: 220px;
    &::placeholder {
      color: #A1B4C6;
    }
  //   &[type="date"] {
  //     position: relative;
  //     &::-webkit-inner-spin-button,
  //     &::-webkit-calendar-picker-indicator {
  //       // display: none;
  //       // -webkit-appearance: none;
  //       // background: red;
  //       top: 0;
  //       left: -22px;
  //       width: 38px;
  //       height: 46px;
  //       position: absolute;
  //       // background: rgba(255,255,255,0);
  //       opacity: 0;
  //     }
  //     &::-webkit-datetime-edit {
  //       color: #A1B4C6;
  //     }
  //     &:valid::-webkit-datetime-edit {
  //       color: #070933;
  //     }
  //     @supports (-webkit-touch-callout: none) {
  //       margin-left: 46px;
  //       width: calc(100% - 46px);
  //       max-width: calc(220px - 46px);
  //       padding-left: 16px!important;
  //     }				  
  //   }
  }

  button {
    width: 100%;
    max-width: 220px;
    text-align: center;
    display: block;
    cursor: pointer;
    @include poppins(700);
    border: none;
  }
  ul {
    padding-bottom: 20px;
  }
  li {
    padding-bottom: 20px;
  }

  .react-datepicker-wrapper {
    input {
      padding-left: 46px;
    }
  }

  .react-datepicker {
    &__navigation {
      display: flex;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      border: none;
      height: 32px;
      width: 32px;
    }

    &__navigation-icon::before {
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: "";
      display: block;
      height: 9px;
      position: absolute;
      top: 6px;
      width: 9px;
    }
  }
  @media (min-width: 1024px) {
    .custom__commonWidth {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        max-width: 8.5em;
        padding-top: 0;
        padding-bottom: 0;
      }
      form {
        margin-left: 2em;
        margin-right: 0;
      }
    }
  }
}