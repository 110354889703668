.QM-deal-admin_sidebar {
  box-shadow: inset 0px -1px 0px #ebeff2;
  background-color: var(--col-bg-2);

  &_header {
    font-size: 14px;
    color: var(--col-btn);
    padding: 22px 0;
    border-bottom: 1px solid var(--col-clr-border);

    &_text {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;

      &::before {
        margin-right: 10px;
      }
    }
  }

  &_emptyState {
    &_text {
      white-space: pre-wrap;
      text-align: center;
      max-width: 300px;
      padding: 50vh 48px 0 48px;
    }
  }
}
