@import '/src/commonStyles/vars.scss';

.QM_E-chart {
  height: 700px;
  position: relative;
  padding-top: 144px;
  display: flex;
  align-items: flex-start;

  &_wrapper {
    position: relative;
    min-width: 710px;
  }
  &_arrow {
    &_wrapper {
      display: flex;
      align-items: center;
      margin-right: -100px;
    }

    &_text {
      text-transform: uppercase;
      text-align: right;
      max-width: 100px;
      margin-right: 10px;
    }
  }
  &_iceberg {
  }
  &_dataContainer {
    position: absolute;
    &1 {
      top: 93px;
      left: 234px;
      &_circle {
        position: absolute;
        bottom: 0;
      }
      &_line {
        position: absolute;
        bottom: 14px;
        left: 8px;
      }

      &_text {
        max-width: 270px;
        position: relative;
        top: -44px;
        left: 143px;
      }
    }
    &1__withData {
      top: 93px;
      left: 99px;
      &_circle {
        position: relative;
        bottom: 0;
        left: 134px;;
      }
      &_line {
        position: relative;
        bottom: 14px;
        left: 0;
        transform: scaleX(-1);
      }

      &_text {
        max-width: 270px;
        position: relative;
        top: -125px;
        left: 21px;
        .QM_E-chart_text {
          color: $estimator-chart-text-color2;
        }
      }
    }
    &2 {
      top: 154px;
      left: 292px;
      &_circle {
        position: absolute;
        bottom: 0;
      }
      &_line {
        position: absolute;
        bottom: 14px;
        left: 8px;
      }

      &_text {
        max-width: 270px;
        position: relative;
        top: -26px;
        left: 143px;
      }
    }
    &3 {
      top: 194px;
      left: 328px;
      &_circle {
        position: absolute;
        bottom: 0;
      }
      &_line {
        position: absolute;
        bottom: 8px;
        left: 14px;
      }

      &_text {
        max-width: 270px;
        position: relative;
        top: 0px;
        left: 139px;
      }
    }
    &4 {
      top: 368px;
      left: 382px;
  
      &_circle {
        position: relative;
        top: -85px;
      }
      &_line {
        position: absolute;
        top: -78px;
        left: 14px;
      }

      &_text {
        max-width: 270px;
        position: relative;
        top: -62px;
        left: 56px;
        
        h3 {
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }
    }
  }

  &_buttonSection {
    display: flex;
    justify-content: space-around;
    padding: 40px;

    .QM_E-btn {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &_helper {
    position: absolute;
    &__rwi {
      left: 240px;
      top: 28px;
    }
    &__underlyingInsurance{
      left: 362px;
      top: 202px;
    }
    &__cheaper {
      left: 130px;
      top: 368px;
    }
  }
  &_text {
    font-family: OpenSans;
    &__bold-36 {
      font-weight: 700;
      font-size: 36px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__bold-24 {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__bold-18 {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $estimator-chart-text-color1;
        text-align: center;
      }
    &__bold-14 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $estimator-chart-text-color2;
    }
    &__bold-13 {
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__bold-12 {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__regular-14 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__regular-12 {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: $estimator-chart-text-color1;
    }
    &__costContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      span:first-child {
        margin-bottom: 10px;
      }
    }
  }
}