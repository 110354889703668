.due-dilligence-tab {
  &__description {
    margin: 24px;
    color: var(--col-label-text);
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  &__content {
    margin: 24px;
  }

  &__title,
  &__list {
    font-size: 14px;
    line-height: 20px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    color: var(--col-active-text);
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 14px;
    color: var(--col-label-text);

    & span:first-child {
      color: var(--col-active-text);
    }

    & p:last-child {
      position: relative;
      & span {
        position: absolute;
        left: 55px;
        top: -3px;
        color: var(--col-status-success);
      }
    }
  }
}
