@import '/src/commonStyles/mixins.scss';

.QM-accordion {
  &_summary {
    @include rubik;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    color: var(--col-active-text);
    padding: 25px 14px;
    cursor: pointer;

    &:hover {
      background-color: var(--col-table-row-hover);
    }
    &::marker {
      display: none;
    }

    &::before {
      @include icomoon;
      content: "\e921";
      font-size: 24px;
      color: var(--col-label-text);
    }

  }
  &[open] > &_summary {
    &:hover {
      background-color: var(--col-bg-2);
    }
    &::before {
      content: '\e920';
    }
  }
}