@import '/src/commonStyles/vars.scss';

.QM_E-tooltip {
  &_content {
    font: {
      family: OpenSans;
      weight: 400;
      size: 12px;
    }
    line-height: 18px;
    color: $input-text-color;

    min-width: 230px;

    &__error {
      min-width: fit-content;
    }
  }

  &_trigger {
    margin-left: 4px;
    cursor: pointer;
    opacity: 0.3;
    stroke: $input-text-color;
    &:hover {
      opacity: 1;
    }

    &__dark {

    }

    &__light {
      stroke: $input-bg;
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }

    &__error {
      stroke: $input-error-border-color;
      visibility: visible;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
}