.faq-page {
  padding: 14px;
  color: var(--col-active-text);
  line-height: 28px;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 650px;

  &__content {
    font-size: 14px;
  }

  .QM-tile {
    padding-bottom: 24px;
  }

  .QM-btn {
    display: inline;
    padding: 4px 16px;
    font-size: 14px;
  }

  .QM-faq-btn {
    &__secondary {
      font-weight: 400;
      background-color: var(--col-table-row-hover);
      color: var(--col-btn);
      border: transparent;
      padding: 2px 16px;
      position: relative;
      top: 3px;
      border: 1px solid transparent;

      span {
        position: relative;
        top: -3px;
      }

      .icon-plus {
        top: 1px;
      }

      &:hover {
        background-color: var(--col-btn-s-hover);
        border: 1px solid var(--col-btn);
      }
    }

    &__submit {
      background-color: transparent;
      color: var(--col-btn);

      &:hover {
        background-color: var(--col-btn);
        border: 1px solid transparent;
        color: var(--col-white);
      }

      &-inactive {
        border: 1px solid var(--col-btn-s-disabled);
        color: var(--col-input-border);
        background-color: var(--col-btn-s-disabled);
      }
    }

    &__active {
      background-color: var(--col-status-success);
      border: none;
      padding: 12px 40px;
    }
  }

  .QM-accordion_summary {
    font-weight: 500;
    font-size: 14px;
  }

  &__header,
  &__description,
  &__item {
    padding-left: 14px;
  }

  &__header {
    font-size: 20px;
    padding-top: 24px;
  }

  &__description {
    font-size: 20px;
    padding-top: 12px;
  }

  &__functions-overview-title {
    font-size: 14px;
    padding-bottom: 25px;
  }

  &__functions-overview-list {
    font-size: 14px;
    list-style-type: disc;
    padding-right: 24px;
  }

  &__functions-overview-item {
    margin-left: 24px;
  }

  &__secondary-text {
    color: #00a6a9;
  }

  &__contacts {
    display: flex;
    padding-right: 14px;
    padding-bottom: 24px;

    p {
      padding-right: 10px;
    }
  }

  &__caption {
    font-size: 10px;
    font-weight: 300;
    margin-top: -10px;
  }

  .faq-img {
    &__figure {
      margin-right: 14px;
      margin-top: 12px;
    }
    &__full-size {
      width: 100%;
      border: 1px solid transparent;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    }
  }

  &__status-title {
    padding-top: 12px;
    font-weight: 700;
  }

  &__status-img {
    position: relative;
    top: 18px;
    left: -4px;
  }

  &__caption-status {
    position: relative;
    left: 15px;
  }
}
