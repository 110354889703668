@import '/src/commonStyles/vars.scss';

.QM_E {
  &-wrapper {
  background-color: $estimator-fallback-bg;
  background: $estimator-bg-gradient;
  min-height: 100vh;

    h1 {
      font: {
        size: 32px;
        weight: 700;
        family: OpenSans;
      }
      max-width: 610px;
      line-height: 45px;
      color: $input-bg;
      margin-bottom: 7px;
    }

    h2 {

    }
    .QM_E-subtext {
      font: {
        size: 16px;
        weight: 400;
        family: OpenSans;
      }
      line-height: 30px;
      color: $input-bg;
      max-width: 745px;
    }

    .QM-commonHeader {
      background: linear-gradient(180deg, rgba(251, 253, 255, 0.38) 0%, transparent 100%);
    }
  }

  &-centering {
    max-width: 1440px;
    margin: 0 auto;
    padding: 30px 50px;
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }
}