@import '/src/commonStyles/vars.scss';

.QM_E-bundleCard {
  background: $estimator-chart-card-bg;
  background-color: $estimator-chart-card-bg-fallback;
  box-shadow: $estimator-chart-card-shadow;
  border-radius: 8px;
  padding: 35px 16px;
  max-width: 430px;
  min-width: 227px;
  border: solid 1px rgba($estimator-chart-text-color1, .3);
  margin-right: 14px;
  position: absolute;
  transition: .5s ease-in-out;
  height: 289px;
  cursor: pointer;
  &:hover {
    box-shadow: $estimator-chart-card-shadow-hover;
    border: solid 1px rgba($estimator-chart-text-color1, .5);
    // height: 295px;
  }

  &__active {
    height: 340px;
    padding-top: 61px;
    z-index: 3;
    left: 240px;
    &:hover {
    }
  }

  &__first {
    z-index: 1;
    left: 0;
  }

  &__last {
    z-index: 2;
    left: 481px;
  }

  &:only-child {
    margin-left: 40px;
    height: auto;
    position: static;
    cursor: default;
  }

  &:nth-child(2) {
    order: 3;
  }
  
  &:nth-child(1) {
    order: 2;
  }
  
  &:nth-child(3) {
    order: 1;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_chart {
    display: flex;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 25px;
    &_arrow {
      margin: 0 10px;
    }
  }
  &_button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    & > .QM_E-chart_text {
      margin-right: 12px;
    }

    &:hover {
      & > .QM_E-chart_text {
        color: $primary-btn-color-hover;
      }
      & > svg {
        stroke: $primary-btn-color-hover;
      }
    }
  }
}